import React from "react"
import {Row, Col, Card, Form, InputNumber, Switch} from "antd"
import PlzInput from "./PlzInput"
import styled from "styled-components"

export function FilterPane ({filtered, setFiltered, age, setAge, children, setChildren, sortByDistance, setSortByDistance,
    setCurrentPosition, setCurrentCenter, currentZoom, setCurrentZoom}) {
    return (<Row style={{maxWidth:450}} gutter={[16, 16]}>
        <Col xs={{ span: 24, order: 2 }} sm={{ span: 24, order: 2 }}>
          <Row>
            <Col span={19}>Nach Alter/Kindern filtern</Col>
            <Col span={5}>
              <Switch
                checked={filtered}
                onChange={(v) => setFiltered(v)}
              />
            </Col>
          </Row>
          {filtered ? (
            <Card size="small" style={{ marginTop: 3 }}>
              <Form
                size={"small"}
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 16,
                }}
                layout="horizontal"
              >
                <Row>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item label="Dein Alter">
                      <InputNumber
                        min={18}
                        max={99}
                        value={age}
                        defaultValue={18}
                        onChange={(v) => setAge(parseInt(v))}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                    <Form.Item label="Anzahl Kinder">
                      <InputNumber
                        min={0}
                        max={99}
                        value={children}
                        defaultValue={0}
                        onChange={(v) => setChildren(parseInt(v))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          ) : null}
        </Col>
        <Col
          xs={{ span: 24, order: 1 }}
          sm={{ span: 24, order: 1 }}
        >
          <Row>
            <Col span={19}>Nach Entfernung sortieren/auf PLZ fokussieren</Col>
            <Col span={5}>
              <Switch
                checked={sortByDistance}
                onChange={(v) => setSortByDistance(v)}
              />              
            </Col>
            <Col span={24}>
            {sortByDistance
              ? <DistanceSort 
                    setCurrentPosition={setCurrentPosition}
                    setCurrentCenter={setCurrentCenter}
                    currentZoom={currentZoom}
                    setCurrentZoom={setCurrentZoom}
                 />
              : null}
            </Col>
          </Row>
        </Col>
      </Row>)
}

const StyledNoticeLocation = styled.div`
background-color: #bcecf478;
color: #557277;
padding: 0.5em;
border-radius: 2px;
border: 1px solid #5572771f;
margin-top: 10px;
margin-bottom: 5px;
`

function DistanceSort ({setCurrentPosition, setCurrentCenter, currentZoom, setCurrentZoom}) {
    return (
        <StyledNoticeLocation>
        <p>
          Wenn sie nach Entfernung sortieren möchten benötigen wir ihre Postleitzahl. Die Berechnung findet direkt in ihrem Browser statt, die PLZ wird nicht an uns übertragen.
                </p>                
                <PlzInput
                  onPosition={(pos) => {
                    //setCurrentTab("map");
                    setCurrentPosition(pos);
                    setCurrentCenter(pos);
                    if (currentZoom < 10) {
                      setCurrentZoom(10);
                    }
                  }}
                />
                </StyledNoticeLocation>
    )
}