import React from "react";
import styled, {createGlobalStyle} from "styled-components"

const MarkerGlobalStyles = createGlobalStyle`
.marker-popover .ant-popover-inner-content {
  padding: 0;
}
`

const MarkerStyles = styled.div`
.markers {
  position: absolute;
  transform: translate(
    ${p=>p.left - p.imageOffset.left}px,
    ${p=>p.top - p.imageOffset.top}px
  );
  cursor: ${p=>p.clickable ? "pointer" : "default"};
}
`

const pin = require("./pin.png");
const pinRetina = require("./pin@2x.png");
const pinHover = require("./pin-hover.png");
const pinHoverRetina = require("./pin-hover@2x.png");
const { Popover } = require("antd");

const imageOffset = {
  left: 15,
  top: 31,
};

class Marker extends React.Component {  

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };
  }

  // what do you expect to get back with the event
  eventParameters = (event) => ({
    event,
    anchor: this.props.anchor,
    payload: this.props.payload,
  });

  // controls
  isRetina() {
    return typeof window !== "undefined" && window.devicePixelRatio >= 2;
  }

  // modifiers
  isHover() {
    return typeof this.props.hover === "boolean"
      ? this.props.hover
      : this.state.hover;
  }

  image() {
    return this.isRetina()
      ? this.isHover()
        ? pinHoverRetina
        : pinRetina
      : this.isHover()
      ? pinHover
      : pin;
  }

  // lifecycle

  componentDidMount() {
    let images = this.isRetina()
      ? [pinRetina, pinHoverRetina]
      : [pin, pinHover];

    images.forEach((image) => {
      let img = new window.Image();
      img.src = image;
    });
  }

  // delegators

  handleClick = (event) => {
    this.props.onClick && this.props.onClick(this.eventParameters(event));
  };

  handleContextMenu = (event) => {
    this.props.onContextMenu &&
      this.props.onContextMenu(this.eventParameters(event));
  };

  handleMouseOver = (event) => {
    this.props.onMouseOver &&
      this.props.onMouseOver(this.eventParameters(event));
    this.setState({ hover: true });
  };

  handleMouseOut = (event) => {
    this.props.onMouseOut && this.props.onMouseOut(this.eventParameters(event));
    this.setState({ hover: false });
  };

  // render

  render() {
    const { left, top, onClick } = this.props;

    return (
      <MarkerStyles left={left} top={top} imageOffset={imageOffset} clickable={!!onClick}>
        <div
          className="markers"
          onClick={this.handleClick}
          onContextMenu={this.handleContextMenu}
          onMouseOver={this.handleMouseOver}
          onMouseOut={this.handleMouseOut}
        >
          <Popover
            overlayClassName="marker-popover"
            content={this.props.component}
            trigger="click"
            placement="bottom"
            style={{ padding: 0 }}
          >
            <img src={this.image()} width={29} height={34} alt="" />
          </Popover>
        </div>        
        <MarkerGlobalStyles />
      </MarkerStyles>
    );
  }
}
export default Marker;
