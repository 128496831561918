import "antd/dist/antd.variable.min.css";
import "../iframeResizer.contentWindow.min.js";
import React, {useState, useEffect} from "react"
import {ConfigProvider} from "antd"
import {render} from "react-dom"
import { Map } from "../components/Map";

import ADRESSES from "../out/adresses.json"

function AdressMap () {
    const [adresses, setAdresses] = useState([ADRESSES])
    /*useEffect(()=>{
        (async ()=> {
           const response = await fetch("/adresses.json")
           const json = await response.json()
           setAdresses(json)
        })()
    }, [])*/

    return <Map initialCenter={[51.07406058399357, 10.692255468750005]} addresses={adresses[0]} />
}

ConfigProvider.config({
    theme: {
        primaryColor: "#f5827d"
    }
})

render(<ConfigProvider><AdressMap /></ConfigProvider>, document.getElementById("root"))