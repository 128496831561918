import React from "react"
import {Row, Col, Button} from "antd"
import PlzInput from "./PlzInput";

export const Searchbar = (props) => {
    function getPosition() {
      navigator.geolocation.getCurrentPosition(
        (pos) => props.onPosition([pos.coords.latitude, pos.coords.longitude]),
        (_) =>
          message.error(
            "Standort kann nicht ermittelt werden. Eventuell Rechte unterbunden."
          )
      );
    }
  
    return (
      <Row
        align="middle"
        justify="space-around"
        style={{ width: "100%", height: "100%" }}      
      >
        <Col>
          <Button type="primary" size="small" onClick={getPosition}>
            Standort lokalisieren
          </Button>
        </Col>
        <Col>
          <PlzInput onPosition={props.onPosition} />
        </Col>
      </Row>
    );
  };