import React, {useState} from "react"
import {Button, Row, Radio, Modal} from "antd"
import {SearchOutlined} from "@ant-design/icons"
import styled from "styled-components"

const Toolbar = styled(Row)`
    padding-left: 10px;
    padding-right: 10px;
    height: 64px;
    background: white;
    border: none;
`

const FilterModal = styled(Modal)`
    .ant-modal-close-x {
        padding-top: 19px;
    }
`

export function NavigationBar ({
    currentTab,
    setCurrentTab,
    filtered,
    filterPane
}) {
    const [filterPaneOpen, setFilterPaneOpen] = useState(false)
    function onTabChange (e) {
        setCurrentTab(e.target.value)
    }
    function onFilterClick (e) {
        setFilterPaneOpen(!filterPaneOpen)
    }
    return (
    <Toolbar align="middle" justify="space-between">
        <Radio.Group buttonStyle="solid" value={currentTab} onChange={onTabChange}>
            <Radio.Button value="map">Karte</Radio.Button>
            <Radio.Button value="addresses">Liste</Radio.Button>
        </Radio.Group>
        <Button onClick={onFilterClick} type="primary" shape="circle" icon={<SearchOutlined />} />        
        <FilterModal title="Filtern &amp; Sortieren"
         visible={filterPaneOpen} onCancel={()=>{setFilterPaneOpen(false)}}
         footer={null}
         >{filterPane}</FilterModal>
    </Toolbar>
    )
}