import React, { useMemo, useState, useEffect } from "react";
import {Map as PigeonMap, ZoomControl} from "pigeon-maps";
import Marker from "./Marker";
import { distanceTo } from "geolocation-utils";
import { Searchbar } from "./Searchbar";
import { NavigationBar } from "./NavigationBar";
import { AddressList, AddressCard } from "./AddressList";
import { FilterPane } from "./FilterPane";
import styled from "styled-components"
import {
  Typography,
  Tabs, 
  Card,
  Layout,
  Row,
  Col,  
  Modal
} from "antd";


const OSM_PROVIDER = (x, y, z) => {
  const s = String.fromCharCode(97 + ((x + y + z) % 3));
  return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
};

const MAP_PROVIDER = OSM_PROVIDER;

function StyledMarker(props) {
  return (
    <div style={{ cursor: "pointer" }}>
      <Marker {...props} />
    </div>
  );
}

const StyledNoticeLocation = styled.div`
background-color: #bcecf478;
color: #557277;
padding: 0.5em;
border-radius: 2px;
border: 1px solid #5572771f;
`

function NoticeLocation() {
  return (
    <StyledNoticeLocation>
      <p>
        Wenn Du nach Entfernung sortieren möchtest hast Du zwei Möglichkeiten:
      </p>
      <ol>
        <li>
          Mit der Schaltfläche
          <Typography.Text keyboard>Standort lokalisieren</Typography.Text>{" "}
          nutze die Ortungsfunktion Deines Webbrowsers oder Gerätes.
        </li>
        <li>
          Über die Postleitzahlsuche wird lokal in ihrem Browser der Standort
          der Postleitzahl ermittelt.
        </li>
      </ol>
      <p>Die Daten werden in keinem Fall an uns übermittelt.</p>      
    </StyledNoticeLocation>
  );
}

function MapPane({
  mobileMode,
  addresses,
  initialCenter,
  currentCenter,
  currentZoom,
  setCurrentZoom,
  setCurrentCenter,
}) {
  const Component = mobileMode ? "div" : Card;
  return (
    <Component style={{ margin: "auto", width: mobileMode ? 320 : 548 }}>      
      <PigeonMap                
        width={mobileMode ? 320 : 500}
        height={mobileMode ? 320 : 500}
        center={currentCenter ? currentCenter : initialCenter}
        zoom={currentZoom}
        provider={MAP_PROVIDER}
      >
        <ZoomControl />
        {addresses.map((a) => (
          <StyledMarker
            component={
              <AddressCard address={a} style={{ width: "300px", margin: 0 }} locateContact={()=>{}}/>
            }
            styles={{ cursor: "pointer" }}
            key={`${a.address_geo_latitude},${a.address_geo_longitude}`}
            anchor={[
              parseFloat(a.address_geo_latitude),
              parseFloat(a.address_geo_longitude),
            ]}
            onClick={(item) => {
              setCurrentCenter([
                item.payload.address_geo_latitude,
                item.payload.address_geo_longitude,
              ]);
              if (currentZoom < 10) {
                setCurrentZoom(10);
              }
            }}
            payload={a}
          />
        ))}
      </PigeonMap>
    </Component>
  );
}

const ContentCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }

  .ant-tabs {
    padding-left: 12px;
    padding-right: 12px;
  }
`

export function Map(props) {
  const [currentCenter, setCurrentCenter] = useState(null);
  const [sortByDistance, setSortByDistance] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [currentTab, setCurrentTab] = useState("map");
  const [mobileMode, setMobileMode] = useState(false);
  const [currentZoom, setCurrentZoom] = useState(5.7);
  const [age, setAge] = useState(18);
  const [children, setChildren] = useState(0);
  const [filtered, setFiltered] = useState(false);
  function reportWindowSize() {
    if (window.innerWidth < 610) {
      setMobileMode(true);
    } else {
      setMobileMode(false);
    }
  }

  useEffect(() => {
    reportWindowSize();
    window.onresize = reportWindowSize;
  }, []);
  const addressesWithDistance = useMemo(
    () =>
      props.addresses
        .filter((a) => {
          return (
            !filtered || (a.min_children <= children && a.min_alter <= age)
          );
        })
        .map((a) => {
          if (!currentPosition || !sortByDistance) return a;
          let distance = distanceTo(
            {
              lat: parseFloat(a.address_geo_latitude),
              lon: parseFloat(a.address_geo_longitude),
            },
            { lat: currentPosition[0], lon: currentPosition[1] }
          );
          return { ...a, distance: distance };
        })
        .sort((a, b) => (a.distance < b.distance ? -1 : +1)),
    [props.addresses, currentPosition, sortByDistance, age, children, filtered]
  );
  return (
    <Layout style={{ width: "100%", maxWidth: 720 }}>
      <Layout.Header theme="light" style={{ padding: 0 }}>
        <NavigationBar
          filtered={filtered}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          onPosition={(pos) => {
            setCurrentPosition(pos);
            setCurrentCenter(pos);
            if (currentZoom < 10) {
              setCurrentZoom(10);
            }
          }}
          filterPane={<FilterPane 
            filtered={filtered} 
            setFiltered={setFiltered} 
            age={age} setAge={setAge} 
            children={children} 
            setChildren={setChildren}
            sortByDistance={sortByDistance} 
            setSortByDistance={setSortByDistance}
            setCurrentPosition={setCurrentPosition}
            setCurrentCenter={setCurrentCenter}
            currentZoom={currentZoom}
            setCurrentZoom={setCurrentZoom}
            />}
        />
      </Layout.Header>
      <Layout.Content>
        <ContentCard style={{ width: "100%", maxWidth: "720px"}}>
          <Tabs
            activeKey={currentTab}
            type="line"
            onChange={(activeKey) => setCurrentTab(activeKey)}
            renderTabBar={()=>{return null}}
          >
            <Tabs.TabPane style={{ width: "100%" }} key="map" tab="Karte">
              <Row style={{ width: "100%" }}>
                <Col flex="auto">
                  <MapPane
                    minimumZoom={6}
                    mobileMode={mobileMode}
                    addresses={addressesWithDistance}
                    initialCenter={props.initialCenter}
                    currentCenter={currentCenter}
                    currentZoom={currentZoom}
                    setCurrentCenter={setCurrentCenter}
                    setCurrentZoom={setCurrentZoom}
                  />
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane key="addresses" tab="Liste">
              { null /*currentPosition || !sortByDistance ? null : <NoticeLocation /> */}
              <Modal
                title="Standort ermitteln"
                visible={false && sortByDistance && !currentPosition}
                footer={null}
                onCancel={() => setSortByDistance(false)}
              >
                <p>
                  Wenn Du nach Entfernung sortieren möchtest hast Du zwei
                  Möglichkeiten:
                </p>
                <ol>
                  <li>
                    Mit der Schaltfläche
                    <Typography.Text keyboard>
                      Standort lokalisieren
                    </Typography.Text>{" "}
                    nutzt Du die Ortungsfunktion Deines Webbrowsers oder
                    Gerätes.
                  </li>
                  <li>
                    Über die Postleitzahlsuche wird lokal in Deinem Browser der
                    Standort der Postleitzahl ermittelt.
                  </li>
                </ol>
                <p>Die Daten werden in keinem Fall an uns übermittelt.</p>
                <Searchbar
                  onPosition={(pos) => {
                    //setCurrentTab("map");
                    setCurrentPosition(pos);
                    setCurrentCenter(pos);
                    if (currentZoom < 10) {
                      setCurrentZoom(10);
                    }
                  }}
                />
              </Modal>              
              <AddressList 
              addresses={addressesWithDistance} 
              setCurrentZoom={setCurrentZoom}
              setCurrentCenter={setCurrentCenter}
              setCurrentTab={setCurrentTab}
              locateContact={(item) => {
                setCurrentTab("map");
                setCurrentCenter([
                  item.address_geo_latitude,
                  item.address_geo_longitude,
                ]);
                setCurrentZoom(10);
              }}
              />
            </Tabs.TabPane>
          </Tabs>
        </ContentCard>
      </Layout.Content>
      <Layout.Footer style={{backgroundColor: "white"}}>
        <p>(c) 2022 Selbstbestimmt-Steril.de</p>
        <p>Karte entworfen von <a href="mailto:info@thegreenweb.org">J.H.Schmidt (TheGreenWeb.org)</a></p>
        </Layout.Footer>
    </Layout>
  );
}
