import React, { useEffect, useState } from "react";
import { Input, message } from "antd";

export default function PlzInput({ onChange, onPosition }) {
  let [input, setInput] = useState("");
  let [plz, setPLZ] = useState(null);

  useEffect(() => {
    if (!plz && input.length > 0) {
      fetch("/plz_de.json")
        .then((r) => r.json())
        .then((data) => setPLZ(data));
    } else if (plz && input.length === 5) {
      const pos = plz.find((e) => parseInt(input) === e[0]);
      if (pos) {
        onPosition(pos.slice(1));
      } else {
        message.error("Ungültige Postleitzahl!");
      }
    }
  }, [input, plz]);
  if (onChange && plz && input.length === 5) {
    onChange(input);
  }
  return (
    <>
      <Input.Search
        placeholder="Ihre Postleitzahl..."
        size="small"
        type="text"
        style={{ maxWidth: "12em" }}
        onChange={(el) => setInput(el.target.value)}
        value={input}
      />
    </>
  );
}
