import React, {useState} from "react"
import styled from "styled-components"
import {Space, List, Popover, Card, Typography, Row, Col, Modal} from "antd"
import {
  PhoneOutlined,
  MailOutlined,
  CarOutlined,
  GlobalOutlined
} from "@ant-design/icons";
import Icon from "@ant-design/icons";
import IconMap from "./IconMap.svg";


const AddressListItem = ({ address, locateContact }) => {
  return (
    <List.Item>
      <AddressCard address={address} locateContact={locateContact} />
    </List.Item>
  );
};

const MultiLineTitleCard = styled(Card)`
  .ant-card-head-title {
    white-space: normal;
  }
`

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

function MapIcon(props) {
  return <Icon {...props} component={IconMap} />;
}

const ContactCard = ({ address }) => {
  return (
    <Row gutter={[0,0]}>
      <Col>
        {address.address_street} {address.address_street_no} <br />
        {address.address_plz} {address.address_city} <br />     
        {telLink(address.address_fon) ? (
          <div><Space>
            <PhoneOutlined />
            {address.address_fon}
          </Space></div>
        ) : null}       
        {mailto(address.address_email) ? (
          <div><Space>
            <MailOutlined />
            {address.address_email}
          </Space></div>
        ) : null}
        {address.address_website && address.address_website.length > 0 ? (
          <div><Space>
            <GlobalOutlined />
            {address.address_website}
          </Space></div>
        ) : null}
      </Col>
    </Row>
  );
};

const ActionLink = ({ Component, href, title, missingText }) => {
  return href && href.length > 0 ? (
    <Typography.Link target="_blank" href={href}>
      <Component title={title} />
    </Typography.Link>
  ) : (
    <Popover content={missingText} trigger="click">
      <Component title={missingText} />
    </Popover>
  );
};

function telLink(tel) {
  if (!tel || tel.length === 0) {
    return null;
  } else {
    return `tel:${tel.replace(/[ ()]/g, "")}`;
  }
}

function mailto(mail) {
  if (!mail || mail.length === 0) {   
    return null;
  } else {
    return `mailto:${mail}`;
  }
}

export const AddressCard = ({ address, style, locateContact, component }) => {  
  const [currentTab, setCurrentTab] = useState("address-contact");
  const Component = component ?? MultiLineTitleCard
  return (
    <Component
      size="small"
      tabList={[
        { key: "address-contact", tab: "Kontakt" },
        { key: "address-details", tab: "Details" },
      ]}
      tabProps={{ size: "small" }}
      onTabChange={(key) => setCurrentTab(key)}
      style={{
        ...{
          margin: "2px",
          borderRadius: "2px",
          border: "none",
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
        },
        ...style,
      }}
      title={address.address_name}
      extra={
        address.distance ? (
          <IconText
            icon={CarOutlined}
            text={`${Math.round(address.distance / 1000.0)} km`}
            key="list-vertical-distance"
          />
        ) : null
      }
      actions={[
        <Typography.Link onClick={() => locateContact(address)}>
          <MapIcon title="Auf Karte anzeigen..." />
        </Typography.Link>,
        <ActionLink
          Component={PhoneOutlined}
          title="Anrufen..."
          href={telLink(address.address_fon)}
          missingText="Telefonnummer nicht bekannt!"
        />,
        <ActionLink
          Component={MailOutlined}
          title="Mail schreiben..."
          href={mailto(address.address_email)}
          missingText="Mail-Adresse nicht bekannt!"
        />,
        <ActionLink
          Component={GlobalOutlined}
          title="Webseite besuchen..."
          href={address.address_website}
          missingText="Website nicht bekannt!"
        />,
      ]}
    >
      {currentTab === "address-contact" ? (
        <ContactCard address={address} />
      ) : (
        <Row gutter={[12, 32]} style={{ marginBottom: 0 }}>
          <Col span={14}>
            <Typography.Paragraph
              ellipsis={{ rows: 4, expandable: true, symbol: "mehr" }}
            >
              {address.address_info}
            </Typography.Paragraph>
          </Col>
          <Col offset={1} span={9}>
            Behandlung
            <br />
            ab {address.min_alter} Jahren
            <br />
            und {address.min_children} Kindern
          </Col>
        </Row>
      )}
    </Component>
  );
};

export function AddressListOld ({addresses, setCurrentTab, setCurrentCenter, setCurrentZoom}) {
    return (<List
        pagination={{
          pageSize: 3,
        }}
        itemLayout="vertical"
        dataSource={addresses}
        renderItem={(a) => (
          <AddressListItem
            key={`address-${a.address_geo_latitude},${a.address_geo_longitude}`}
            address={a}
            locateContact={(item) => {
              setCurrentTab("map");
              setCurrentCenter([
                item.address_geo_latitude,
                item.address_geo_longitude,
              ]);
              setCurrentZoom(10);
            }}
          />
        )}
      />)
}

const ListItem = styled(List.Item)`
  .ant-list-item-meta-title {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 2px;
  }

  .ant-list-item-meta {
    margin-bottom: 0;
  }

  cursor: pointer;
  &:hover {
    background-color: #f5827e15;
  }
`

const AddressModal = styled(Modal)`
  padding-top: 20px;
  .ant-modal-body {
    padding: 0;
  }

  &&&&& .ant-card-head-title {
    padding-right: 10px;
  }

  .ant-modal-content {
    background-color: transparent;
  }

  .ant-modal-close-x {
    padding-top: 10px;
  }

  .ant-card-extra {
    position: relative;
    top: 40px;
  }

  border: none;
  background-color: transparent;
`

export function AddressList ({addresses, locateContact}) {

  const [selectedAddress, setSelectedAddress] = useState(null)
  function handleClick (a) {
    setSelectedAddress(a)
  }
  return (<><List
      pagination={{
        pageSize: 8,
      }}
      itemLayout="vertical"
      dataSource={addresses}
      renderItem={(a) => (        
          <ListItem onClick={()=>handleClick(a)}>
            <List.Item.Meta title={a.address_name} description={addressDescription(a)}/>
          </ListItem>          
      )}
    />
    <AddressModal closable={true} visible={selectedAddress} onCancel={()=>setSelectedAddress(null)} footer={null}>
      <AddressCard address={selectedAddress} locateContact={(a)=>{setSelectedAddress(null); locateContact(a);}} />
    </AddressModal>
    </>)
}

function addressDescription (address) {
  return `${(address.distance? `${Math.round(address.distance / 1000.0)} km -> `
  : ``)} ${address.address_plz} ${address.address_city}`
}